import { StationListNode } from '@web/context/SearchWidgetContext';

const normalizeValue = (value: string) =>
  value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

const includesInputValue = (value: string, inputValue: string) =>
  normalizeValue(value).includes(normalizeValue(inputValue));

export const filterAndSort = (items: StationListNode[], inputValue: string) =>
  items
    .filter(
      (item) =>
        includesInputValue(item.value, inputValue) ||
        includesInputValue(item.code, inputValue) ||
        includesInputValue(item.country, inputValue),
    )
    .sort((a, b) => {
      // Sort results by matching value with keyword position in input value
      if (
        a.value.toLowerCase().indexOf(inputValue.toLowerCase()) >
        b.value.toLowerCase().indexOf(inputValue.toLowerCase())
      ) {
        return 1;
      } else if (
        a.value.toLowerCase().indexOf(inputValue.toLowerCase()) <
        b.value.toLowerCase().indexOf(inputValue.toLowerCase())
      ) {
        return -1;
      } else {
        // If they are equal we sort them on rank
        if (a.rank > b.rank) {
          return -1;
        } else if (a.rank < b.rank) {
          return 1;
        } else {
          return 0;
        }
      }
    });

export const getItemLevel = (
  item: StationListNode,
  items: StationListNode[],
): 1 | 2 => {
  if (item.parentCode === null) {
    return 1;
  }

  // If the list of results includes this item's parent code, it is a level 2 item
  if (items.find((x) => x.code === item.parentCode)) {
    return 2;
  }

  return 1;
};

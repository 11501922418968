import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Box from '@ui-v2/core/Box/Box';
import Menu from '@ui-v2/core/Menu/Menu';
import { IconType } from '@ui-v2/types/typography';
import {
  SearchState,
  StationListNode,
  useSearchWidget,
} from '@web/context/SearchWidgetContext';
import Input from '@web/core/Forms/Input/Input';
import StationPickerResults from './components/StationPickerResults';
import { useStationPicker } from './hooks/useStationPicker';

export type StationPickerProps = {
  iconLeft: IconType;
  items: StationListNode[];
  name: string;
  onSelection: (item: StationListNode | null | undefined) => void;
  pickerType: SearchState;
  placeholder: string;
  selection: StationListNode | null;
};

const StyledList = styled('ul')(() => [
  css`
    padding: 0;
    list-style: none;
  `,
]);

const StationPicker = forwardRef<HTMLInputElement, StationPickerProps>(
  (
    {
      iconLeft,
      items: defaultItems,
      name,
      onSelection,
      pickerType,
      placeholder,
      selection,
    },
    forwardedRef,
  ) => {
    const {
      handleSetSearchState,
      isDestinationStationsLoading,
      isOriginStationsLoading,
      searchState,
    } = useSearchWidget();

    const {
      closeMenu,
      getInputProps,
      getItemProps,
      getMenuProps,
      highlightedIndex,
      inputValue,
      isOpen,
      isShowingCTAMenu,
      isShowingNoResultsMessage,
      isShowingResults,
      items,
      selectedItem,
      setCTAMenu,
      setInputValue,
      setResultsState,
    } = useStationPicker({
      defaultItems,
      onSelection,
      pickerType,
      searchState,
      selection,
    });

    const handleInputBlur = () => {
      /**
       * Set search state to idle if we're blurring this input without selecting another one
       * Note: When switching directly between inputs this results in an idle state being set before
       * the next input is focused.
       * We could perhaps get around this if we chose to always have the menu open on focus
       * and then we can use the isOpenChange handler
       */
      if (searchState === pickerType) {
        handleSetSearchState('idle');
        setResultsState('idle');
      }

      // If the menu is open, close it
      if (items.length > 0) {
        closeMenu();
      }

      /**
       * If an item has been selected, set that as the value
       * Otherwise clear the input
       */
      if (selectedItem) {
        setInputValue(selectedItem.value);
      } else if (inputValue !== '') {
        setInputValue('');
      }
    };

    const handleInputFocus = () => {
      if (searchState !== pickerType) {
        handleSetSearchState(pickerType);
      }

      // Show the CTA menu on focus
      setCTAMenu();

      // Always clear the input on focus
      if (selectedItem) {
        setInputValue('');
      }
    };

    return (
      <Box position="relative">
        {/* Warning: downshift's getInputProps is typed as any so this input is barely typed */}
        <Input
          label={placeholder}
          name={name}
          placeholder={placeholder}
          variant="floating-label"
          {...getInputProps({
            ref: forwardedRef,
            id: `${name}-input`,
            'aria-controls': `${name}-menu`,
            'aria-labelledby': `${name}-label`,
            value:
              inputValue.substring(0, 15) +
              (inputValue.length > 15 ? '...' : ''),
          })}
          data-cy="autocomplete-input"
          iconLeft={iconLeft}
          isLoading={isDestinationStationsLoading || isOriginStationsLoading}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
          overriddenActiveState={searchState === pickerType || selection}
          overriddenFocusState={searchState === pickerType}
        />
        <Menu align="left" isOpen={isOpen} maxHeight={500} widthCustom={345}>
          <StyledList
            {...getMenuProps({
              id: `${name}-menu`,
              'aria-controls': `${name}-menu`,
              'aria-labelledby': `${name}-label`,
            })}
          >
            <StationPickerResults
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              isShowingCTAMenu={isShowingCTAMenu}
              isShowingNoResultsMessage={isShowingNoResultsMessage}
              isShowingResults={isShowingResults}
              items={items}
              pickerType={pickerType}
            />
          </StyledList>
        </Menu>
      </Box>
    );
  },
);

export default StationPicker;
